import React, { Component } from "react"
import { withPrefix, Link } from "gatsby"
import Helmet from "react-helmet"
import $ from "jquery"
import axios from "axios"
import ImagesLoaded from "react-images-loaded"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import Banner from "../components/banner"
import About from "../sections/about"
import Message from "../sections/message"
import Gallery from "../sections/gallery"
import Services from "../sections/services"
import Contact from "../sections/contact"

import Loader from "../components/loader"
import ScrollTop from "react-scrolltop-button"
import Popup from "reactjs-popup"

import "../styles/home.scss"

import { API_EN } from "../utils/config"

class IndexPage extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.fetchData = this.fetchData.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.state = {
      sections: null,
      loading: true,
      modalOpen: false,
      modalData: null,
      imageLoaded: false,
    }
  }
  handleOnAlways = instance => {
    //console.log("handleOnAlways" ,instance)
  }

  handleOnProgress = (instance, image) => {
    //console.log("handleOnProgress" ,instance, image)
  }

  handleOnFail = instance => {
    //console.log("handleOnFail" ,instance)
  }

  handleDone = instance => {
    //console.log("handleDone" ,instance)
    if (instance.isComplete) {
      this.setState({ imageLoaded: true })
    }
  }

  componentDidUpdate() {}

  componentDidMount() {
    //window.grecaptcha.reset();
    // console.log(window.grecaptcha)
    this._isMounted = true
    this.fetchData([
      "banner",
      "about",
      "ceo-message",
      "services",
      "gallery",
      "settings",
    ])
  }
  componentWillUnmount() {
    this._isMounted = false
  }
  fetchData(pages) {
    const get = endpoint => axios.get(`${API_EN}/${endpoint}`)

    return Promise.all(
      pages.map(async page => {
        const { data: item } = await get(`${page}`)
        const bullData = {}
        bullData.name = page
        bullData.data = item.data
        return bullData
      })
    ).then(res => {
      if (this._isMounted) {
        this.setState({
          sections: res,
          loading: false,
        })
      }
    })
  }
  openModal(data) {
    this.setState({
      modalOpen: true,
      modalData: data,
    })

    this.timeoutId = setTimeout(
      function() {
        //  console.log("-----")
        this.setState({ show_popup: true })
        //
      }.bind(this),
      100
    )
  }
  closeModal() {
    this.setState({
      modalOpen: false,
      show_popup: false,
      modalData: null,
    })
  }
  render() {
    if (!this.state.sections) return <Loader />

    const data = {}
    this.state.sections.map(section => {
      var name = section.name === "ceo-message" ? "ceo_message" : section.name
      data[name] = section.data
    })

    return (
      <>
        {this.state.loading && this._isMounted && this.state.imageLoaded ? (
          <Loader />
        ) : (
          <Layout>
            <SEO title="Home" />
            <ImagesLoaded
              elementType={"div"}
              className={"image-loaded"}
              onAlways={this.handleOnAlways}
              onProgress={this.handleOnProgress}
              onFail={this.handleOnFail}
              done={this.handleDone}
              background=".img"
            >
              <Banner data={data.banner} social={data.settings.social} />
            </ImagesLoaded>
            <About data={data.about} />
            {/* <Message
              data={data.ceo_message}
              handleClick={this.openModal}
            />{" "}
            */}
            <Services data={data.services} />
            <Gallery data={data.gallery && data.gallery[0]} />
            <Contact data={data.settings} />
            <ScrollTop
              text=""
              distance={100}
              breakpoint={768}
              className="scrollUp"
              speed={1000}
            />
            {data.ceo_message ? (
              <Popup
                open={this.state.modalOpen}
                className={this.state.modalOpen ? "show" : ""}
                closeOnDocumentClick
                onClose={this.closeModal}
              >
                <div
                  className="modal modal-messages "
                  data-shown={this.state.show_popup ? "show" : ""}
                >
                  <div className="modal-text">
                    <div
                      className="title"
                      dangerouslySetInnerHTML={{
                        __html: data.ceo_message.sec_title,
                      }}
                    ></div>
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: data.ceo_message.large_description,
                      }}
                    ></div>
                  </div>
                  <a className="modal-close" onClick={this.closeModal}>
                    <i className="icon icon-icons-close"></i>
                  </a>
                </div>
              </Popup>
            ) : null}
          </Layout>
        )}
      </>
    )
  }
}

// export const query = graphql`
//   query JokesQuery {
//       richbulls{
//         home{
//           banner {
//             caption
//           }
//         }
//       }
//     }
// `;

export default IndexPage
