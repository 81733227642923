import React from "react"
import { Link } from "gatsby"
//import Img from "gatsby-image"

import Slider from "react-slick";
import "../styles/vendor/slick.scss";
import "../styles/vendor/slick-theme.scss";

import TrackVisibility from 'react-on-screen';

//import { CSSTransition } from 'react-transition-group'

const Services = ({data}) => {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
         lazyLoad: true,  
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };


    //const getId = (str) => str.replace(/\s+/g, '-').toLowerCase();

    return ( 
         <TrackVisibility offset={350} once tag="section" className="services-view">
            {({isVisible}) =>
                <section className="home-section services" data-visible={isVisible ? 'visible' : 'invisible'} id="services">
                    <div className="container">
                        <div className="section-block fadeUpBlock"> 
                            <div className="line __right"></div>
                            <h2 className="section-title"><span>Services</span></h2>
                            <div className="slider-wrap ">
                                <Slider className="services-slider" {...settings}>
                                    {
                                        data.map((slide, index) => 
                                            <div className="services-slide" key={slide.post_title}>
                                                <Link to={`/services/#${slide.post_id}`}>
                                                    <div className="services-img">
                                                        <figure>
                                                            <div className="img" style={{ backgroundImage: `url(${slide.service_image})` }}></div> 
                                                        </figure>
                                                    </div>
                                                    <div className="services-text">   
                                                        <div className="services-title"><span>{slide.post_title}</span></div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    }
                                </Slider>
                                <Link to="/services" className="more">View All</Link>
                            </div>
                        </div>
                    </div> 
                </section>   
            }
        </TrackVisibility>
    )
};
 
export default Services;
    
