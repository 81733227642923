export const normalizePhone = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}${onlyNums.slice(3, 6)}${onlyNums.slice(6, 10)}`;
};


export const normalizeAlpha = value => {
    if (!value) {
        return value;
    }
    if(!value.match(/[^a-zA-Z ]/)) return value
}

export const normalizeAlphaNumericSpecial = value => {
    if (!value) {
        return value;
    }
    if(!value.match(/[^a-zA-Z0-9-,.\n ]/)) return value
}
