import React, { Component } from "react"
//import { useStaticQuery, graphql } from "gatsby"
//import Img from "gatsby-image"
import axios from "axios"
import { reset } from "redux-form"
import swal from "sweetalert"

import { API_CONTACT } from "../utils/config"

import ContactForm from "../components/contact-form"
import mapimg from "../images/map.jpg"
import TrackVisibility from "react-on-screen"

const mapImg = {
  backgroundImage: `url(${mapimg})`,
}

class Contact extends Component {
  constructor(props) {
    super(props)
    //console.log(dispatch)
    this.state = {
      loader: false,
    }

    this.submit = this.submit.bind(this)
  }
  submit(values, dispatch) {
    this.setState({ loader: true })
    const axRes = axios({
      method: "post",
      url: API_CONTACT,
      data: values,
    })
      .then(response => {
        this.setState({
          loader: false,
        })

        if (response.status === 200 && response.data.status) {
          dispatch(reset("contact"))
          swal(
            response.data.title ? response.data.title : "Thank You",
            response.data.message
              ? response.data.message
              : "Your message was sent successfully.",
            "success"
          ).then(value => {
            //console.log(value)
          })
        } else {
          dispatch(reset("contact"))
          swal(
            response.data.title ? response.data.title : "Error",
            response.data.message
              ? response.data.message
              : "Failed To Send Your Message.",
            "error"
          ).then(value => {
            //console.log(value)
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  componentWillUnmount() {}
  render() {
    return (
      <TrackVisibility offset={350} once tag="section" className="contact-view">
        {({ isVisible }) => (
          <section
            className="home-section contact"
            data-visible={isVisible ? "visible" : "invisible"}
            id="contact-us"
          >
            <div className="container">
              <div className="section-block">
                <div className="section-img fadeUpBlock">
                  <figure>
                    <div className="img" style={mapImg}>
                      <a href="https://g.page/richbulls" target="_blank">
                        <i className="icon icon-garphics-icon-location"></i>
                      </a>
                    </div>
                  </figure>
                </div>
                <div className="section-content fadeUpBlock">
                  <h2 className="section-title text-right">
                    <span>Contact us</span>
                  </h2>
                  <ContactForm
                    onSubmit={this.submit}
                    loader={this.state.loader}
                    captcha={this.props.data.google_captcha_key}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
      </TrackVisibility>
    )
  }
}

export default Contact
