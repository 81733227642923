import React from "react"
import { Link } from "gatsby"

import aboutimg from "../images/about-img.png";

import TrackVisibility from 'react-on-screen';
import { useSpring, animated } from 'react-spring'

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`

const About = ({data}) => {
    const aboutBg = {
        backgroundImage: `url(${aboutimg})`
    }
    const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))
    //console.log(data)
    return (
        <TrackVisibility offset={350} once tag="section" className="about-view">
            {({isVisible}) =>
                <section className="home-section about" data-visible={isVisible ? 'visible' : 'invisible'} id="about">
                    <div className="container" onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
                    <div className="section-block">
                        <div className="section-img fadeUpBlock">
                        <h2 className="section-title"><span>{data.post_title}</span></h2>
                        <animated.div className="placeholder" style={{ transform: props.xy.interpolate(trans1) }} >
                            <div className="img" style={aboutBg}>
                            </div>
                        </animated.div>
                        </div>
                        <div className="section-content">
                        <div className="line __top"></div>
                        <div className="line __right"></div>
                        <div className="text fadeUpBlock" dangerouslySetInnerHTML={ {__html: data.description} }>
                            {/* <p><strong>Lorem Ipsum is simply</strong> dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five <strong>centuries</strong>, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release</p> */}
                            {/* <Link to="#" className="more">Read more</Link> */}
                        </div>
                        </div>
                    </div>
                    </div>
                </section>  
            }
        </TrackVisibility>
    )
};
 
export default About;
    
