import React, { Component } from "react"
import { useStaticQuery, graphql } from "gatsby"
import $ from "jquery"
import Img from "gatsby-image"

import Slider from "react-slick"
import "../styles/components/banner.scss"
import "../styles/vendor/slick.scss"
import "../styles/vendor/slick-theme.scss"

// import img1 from "../images/banner-img1.jpg"
// import img2 from "../images/banner-img2.jpg"
// import img3 from "../images/banner-img3.jpg"

import { useSpring, animated } from "react-spring"

//import "../scripts/imagesloaded.pkgd.min"
//import "../scripts/three.min"
//import "../scripts/TweenMax.min"
//import "../scripts/hover"

import TrackVisibility from "react-on-screen"

class Banner extends Component {
  componentDidMount() {
    // imagesLoaded( document.querySelectorAll('img'), () => {
    //     document.body.classList.remove('loading');
    // })
    // Array.from(document.querySelectorAll('.bg-img')).forEach((el) => {
    //     console.log(el)
    //     const imgs = Array.from(el.querySelectorAll('img'));
    //     new hoverEffect({
    //     parent: el,
    //     intensity: el.dataset.intensity || undefined,
    //     speedIn: el.dataset.speedin || undefined,
    //     speedOut: el.dataset.speedout || undefined,
    //     easing: el.dataset.easing || undefined,
    //     hover: el.dataset.hover || undefined,
    //     image1: imgs[0].getAttribute('src'),
    //     image2: imgs[1].getAttribute('src'),
    //     displacementImage: el.dataset.displacement
    //     });
    // });
  }
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 1500,
      fade: true,
      cssEase: "linear",
      lazyLoad: true, 
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnFocus: true,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            dots: true,
          },
        },
      ],
    }

    // const slides = [
    //     {
    //         "img" : img1,
    //         "title" : "Window",
    //         "title1" : "Tinting"
    //     },
    //     {
    //         "img" : img2,
    //         "title" : "Vehicle",
    //         "title1" : "Enhancements"
    //     },
    //     {
    //         "img" : img3,
    //         "title" : "Window",
    //         "title1" : "Tinting"
    //     }
    // ]
    var style = {
      display: "none",
    }

    const { data, social } = this.props

    return (
      <TrackVisibility offset={350} once tag="section" className="home-view">
        {({ isVisible }) => (
          <section
            className="banner"
            id="banner"
            data-visible={isVisible ? "visible" : "invisible"}
            id="banner"
          >
            <div className="inner">
              <ol className="social">
                {social.facebook_link ? (
                  <li>
                    <a href={social.facebook_link} target="_blank">
                      <i className="icon icon-graphics-icon-fb"></i>
                    </a>
                  </li>
                ) : null}
                {social.instagram_link ? (
                  <li>
                    <a href={social.instagram_link} target="_blank">
                      <i className="icon icon-graphics-icon-insta"></i>
                    </a>
                  </li>
                ) : null}
                {social.twitter_link ? (
                  <li>
                    <a href={social.twitter_link} target="_blank">
                      <i className="icon icon-graphics-icon-twtr"></i>
                    </a>
                  </li>
                ) : null}
                {social.youtube_link ? (
                  <li>
                    <a href={social.youtube_link} target="_blank">
                      <i className="icon icon-graphics-icon-youtube"></i>
                    </a>
                  </li>
                ) : null}

                {social.whatsapp_link ? (
                  <li>
                    <a href={social.whatsapp_link} target="_blank">
                      <i className="icon icon-social-whatsapp icon-2-social-whatsapp"></i>
                    </a>
                  </li>
                ) : null}
              </ol>
              <div className="line __left"></div>
              <div className="banner-caption">
                <span>The most luxurious </span>
                <span>car care boutique and lounge</span>
              </div>
              <Slider className="banner-slider" {...settings}>
                {data.map((slide, index) => (
                  <div className="banner-slide" key={slide.post_id}>
                    {/* <div style={ style } className="bg-img" data-displacement="img/displacement/6.jpg" data-intensity="0.6" data-speedin="1.2" data-speedout="0.5">
                                                <img src={slide.img} alt="Image"/>
                                                <img src={slide.img} alt="Image"/>
                                            </div> */}
                    <div className="banner-bg">
                      <div
                        className="img"
                        style={{
                          backgroundImage: `url(${slide.banner_image})`,
                        }}
                      ></div>
                    </div>
                    <div className="banner-text">
                      <div className="banner-title">
                        <span>{slide.post_title_1}</span>
                        <span>{slide.post_title_2}</span>
                      </div>
                      <div className="banner-info">
                        <div className="info-inner">
                          <em>{data[index + 1] ? "Next" : "Prev"}</em>
                          <div>
                            <span>
                              {data[index + 1]
                                ? data[index + 1].post_title_1
                                : data[index].post_title_1}
                            </span>
                            <span>
                              {data[index + 1]
                                ? data[index + 1].post_title_2
                                : data[index].post_title_2}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </section>
        )}
      </TrackVisibility>
    )
  }
}

// const Image = (props) => {
//   const data = useStaticQuery(graphql`
//     query {
//       placeholderImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
//         childImageSharp {
//           fluid(maxWidth: 300) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   `)

//   return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
// }

export default Banner
