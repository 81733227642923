import React from "react"

import { Field, reduxForm } from "redux-form"
import ReCAPTCHA from "react-google-recaptcha"

import submitIcon from "../images/submit-icon.png"
import {
  required,
  alphaNumeric,
  alpha,
  email,
  number,
  phoneNumber,
  maxLength,
} from "../utils/FormValidation"
import {
  normalizePhone,
  normalizeAlpha,
  normalizeAlphaNumericSpecial,
} from "../utils/FormNormalize"

const renderField = ({
  input,
  placeholder,
  type,
  options,
  meta: { touched, error, warning },
}) => {
  if (type === "textarea") {
    return (
      <>
        <textarea
          {...input}
          placeholder={placeholder}
          className={`form-control ${
            touched ? (error || warning ? "is-invalid" : "is-valid") : ""
          }`}
        />
        {touched &&
          ((error && <div className="invalid-feedback">{error}</div>) ||
            (warning && <div className="invalid-feedback">{warning}</div>))}
      </>
    )
  } else {
    return (
      <>
        <input
          {...input}
          placeholder={placeholder}
          type={type}
          className={`form-control ${
            touched ? (error || warning ? "is-invalid" : "is-valid") : ""
          }`}
        />
        {touched &&
          ((error && <div className="invalid-feedback">{error}</div>) ||
            (warning && <div className="invalid-feedback">{warning}</div>))}
      </>
    )
  }
}

const renderRecaptchaField = field => {
  const {
    meta: { touched, error, warning },
  } = field
  return (
    <div className="captcha-field">
      <ReCAPTCHA
        sitekey={field.captchakey}
        onChange={field.input.onChange}
        className={`re-captcha ${
          touched ? (error || warning ? "is-invalid" : "is-valid") : ""
        }`}
      />
      {touched &&
        ((error && <div className="invalid-feedback">{error}</div>) ||
          (warning && <div className="invalid-feedback">{warning}</div>))}
    </div>
  )
}

const ContactForm = props => {
  const { handleSubmit, pristine, reset, submitting } = props
  const loader = props.loader ? (
    <div className="ajaxLoader">
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </div>
  ) : (
    ""
  )
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-field">
            <Field
              name="name"
              component={renderField}
              type="text"
              placeholder="Name"
              className="form-control"
              validate={[required]}
              normalize={normalizeAlpha}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-field">
            <Field
              name="phone"
              component={renderField}
              type="text"
              placeholder="Phone"
              className="form-control"
              validate={[required]}
              normalize={normalizePhone}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-field">
            <Field
              name="email"
              component={renderField}
              type="email"
              placeholder="Email"
              className="form-control"
              validate={[required, email]}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-field">
            <Field
              name="query"
              component={renderField}
              placeholder="Query"
              className="form-control"
              type="textarea"
              rows="3"
              validate={[required]}
              normalize={normalizeAlphaNumericSpecial}
            />
          </div>
        </div>
        <div className="form-row">
          <Field
            name="recaptcha"
            component={renderRecaptchaField}
            captchakey={props.captcha}
            validate={[required]}
          />
        </div>
        <div className="form-row">
          <button type="submit" disabled={pristine || submitting}>
            <span>Send</span>
            <i className="icon icon-graphics-arrow-icon-2">
              <img src={submitIcon} />
            </i>
          </button>
          {/* <button type="button" disabled={pristine || submitting} onClick={reset}>
                    Clear Values
                    </button> */}
        </div>

        {loader}
      </form>
    </>
  )
}

export default reduxForm({
  form: "contact",
  enableReinitialize: true,
})(ContactForm)
