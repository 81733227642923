import React, { Component } from "react"
import { Link } from "gatsby"
// import Img from "gatsby-image"
import $ from "jquery"
import Slider from "react-slick"
import "../styles/vendor/slick.scss"
import "../styles/vendor/slick-theme.scss"

// import img1 from "../images/gallery-img1.jpg";
// import img2 from "../images/gallery-img2.jpg";
// import img3 from "../images/gallery-img3.jpg";
// import img4 from "../images/gallery-img4.jpg";
// import img5 from "../images/gallery-img5.jpg";
// import img6 from "../images/gallery-img6.jpg";

import toolimg from "../images/tool.png"

import TrackVisibility from "react-on-screen"

const toolImg = {
  backgroundImage: `url(${toolimg})`,
}

class Gallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nav1: null,
      nav2: null,
    }
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }
  render() {
    const { data } = this.props
    if (!data) return false
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: "linear",
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (oldIndex, newIndex) => {
        $(".gallery-nav .gallery-nav-slide").removeClass("__active")
        $(
          '.gallery-nav .slick-slide[data-index="' +
            newIndex +
            '"] .gallery-nav-slide'
        ).addClass("__active")
      },
      onInit: () => {
        $(
          '.gallery-nav .slick-slide[data-index="1"] .gallery-nav-slide'
        ).addClass("__active")
      },
    }

    var settings1 = {
      // slidesToShow: 3,
      swipeToSlide: true,
      focusOnSelect: true,
      lazyLoad: true, 
      slidesToShow: data.gallery.length < 6 ? data.gallery.length : 6,
      slidesToScroll: 1,
      dots: false,
      arrows: false,

      // focusOnSelect: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: data.gallery.length < 5 ? data.gallery.length : 5,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: data.gallery.length < 4 ? data.gallery.length : 4,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: data.gallery.length < 3 ? data.gallery.length : 3,
          },
        },
      ],
    }

    // const slides = [
    //     {
    //         "img" : `${img1}`,
    //     },
    //     {
    //         "img" : `${img2}`,
    //     },
    //     {
    //         "img" : `${img3}`,
    //     },
    //     {
    //         "img" : `${img4}`,
    //     },
    //     {
    //         "img" : `${img5}`,
    //     },
    //     {
    //         "img" : `${img6}`,
    //     }
    // ]

    return (
      <TrackVisibility
        partialVisibility
        tag="figure"
        once
        tag="section"
        className="gallery-view"
      >
        {({ isVisible }) => (
          <section
            className="home-section gallery"
            data-visible={isVisible ? "visible" : "invisible"}
            id="gallery"
          >
            <div className="container">
              <div className="section-block">
                <figure className="placeholder">
                  <img src={toolimg} />
                </figure>
                <div className="line __top"></div>
                <div className="line __left"></div>
                <h2 className="section-title">
                  <span>{data.post_title}</span>
                </h2>
                <div className="slider-wrap fadeUpBlock">
                  <Slider
                    className="gallery-slider"
                    {...settings}
                    asNavFor={this.state.nav2}
                    ref={slider => (this.slider1 = slider)}
                  >
                    {data.gallery.map((slide, index) => (
                      <div
                        className="gallery-slide"
                        key={index}
                        data-index-no={index + 1}
                      >
                        <div className="gallery-img">
                          <figure>
                            <div
                              className="img"
                              style={{ backgroundImage: `url(${slide})` }}
                            ></div>
                          </figure>
                        </div>
                      </div>
                    ))}
                  </Slider>

                  <Slider
                    className="gallery-nav"
                    {...settings1}
                    asNavFor={this.state.nav1}
                    ref={slider => (this.slider2 = slider)}
                  >
                    {data.gallery.map((slide, index) => (
                      <div
                        className="gallery-nav-slide"
                        key={index}
                        data-index-no={index + 1}
                      >
                        <div className="gallery-img">
                          <figure>
                            <div
                              className="img "
                              style={{ backgroundImage: `url(${slide})` }}
                            ></div>
                          </figure>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
                <Link to="/gallery" className="more">
                  View All
                </Link>
              </div>
            </div>
          </section>
        )}
      </TrackVisibility>
    )
  }
}

export default Gallery
